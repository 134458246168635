import { PAGING } from './type';

const initialState = null;
let newArray;

const pagingReducer = (state = initialState, action) => {
	switch (action.type) {
		case PAGING:
			newArray = action.object;
			state = newArray;

			return { state };

		default:
			return state;
	}
};

export default pagingReducer;
