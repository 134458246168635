/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/styles/global.css';

// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/storage';
// import "firebase/functions"
// import "firebase/app"
