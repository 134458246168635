import { PLAYVIDEO } from './type';

const initialStatePlayVideo = null;
let newArray;

const playVideoReducer = (state = initialStatePlayVideo, action) => {
	switch (action.type) {
		case PLAYVIDEO:
			newArray = action.object;
			state = newArray;

			return { state };

		default:
			return state;
	}
};

export default playVideoReducer;
