/**
 * Composant d'ordre supèrieur
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { playVideo } from './redux/playVideo/actionPlayVideo';
import PlayerAudio from './utils/PlayerAudios';
import flowers from './images/svg/flowers-bg.svg';
import ModalGlobalMessage from './components/modal/ModalGlobalMessage';
import AOS from 'aos';
import 'aos/dist/aos.css';

let Vimeo;
if (typeof window !== 'undefined') {
	Vimeo = require('@u-wave/react-vimeo').default;
}

const App = ({ children }) => {
	const dispatch = useDispatch();

	const currentAudio = useSelector((state) => state.audio);
	const playVideoRedux = useSelector((state) => state.playVideo);
	const [openWindowVideo, setOpenWindowVideo] = useState(false);

	let playerAudio;
	if (currentAudio) {
		playerAudio = <PlayerAudio sound={currentAudio.state} />;
	}

	useEffect(() => {
		if (playVideoRedux) {
			setOpenWindowVideo(playVideoRedux.state.open);
		}
	}, [playVideoRedux]);

	const CloseVideo = () => {
		// Redux State Close Window Video
		dispatch(
			playVideo({
				id: 0,
				open: false
			})
		);
	};

	const video = (
		<div className="fixed top-0 left-0 z-60 w-full h-screen bg-gradient">
			<div
				className="absolute z-0 w-full h-full opacity-70"
				style={{ backgroundImage: `url(${flowers})`, backgroundSize: '30%' }}
			></div>

			<button
				onClick={() => CloseVideo()}
				className="absolute top-5 right-5 z-60 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-sm mb-1 shadow-lg hover-ride-top-large animate"
			>
				<div className="flex items-center justify-center">
					<svg
						className="h-4 w-4 mr-2"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						strokeWidth="2"
						stroke="currentColor"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path stroke="none" d="M0 0h24v24H0z" />{' '}
						<line x1="18" y1="6" x2="6" y2="18" />{' '}
						<line x1="6" y1="6" x2="18" y2="18" />
					</svg>
					Fermer
				</div>
			</button>

			<div className="relative z-50 w-full h-full flex flex-col items-center justify-center">
				{Vimeo && (
					<Vimeo
						video={playVideoRedux ? playVideoRedux.state.id : 0}
						autoplay={true}
						className={'w-full flex items-center justify-center vimeoPlayer'}
						style={{ height: '100%' }}
					/>
				)}
			</div>
		</div>
	);

	useEffect(() => {
		AOS.init({
			duration: 600,
			easing: 'ease-out-cubic'
		});
	});

	return (
		<div>
			{openWindowVideo ? video : <></>}
			{children}
			<ModalGlobalMessage />
			{playerAudio}
		</div>
	);
};

export default App;
