import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Image from '../../images/photos/illuminated-ancient-corridor-leads-modern-spirituality-generated-by-ai.jpg';
import H2 from '../functional/H2';
import { Link } from 'gatsby';

export default function ModalGlobalMessage() {
	const [open, setOpen] = useState(false);

	const cancelButtonRef = useRef();

	const noLongerSeeMessage = () => {
		localStorage.setItem(`welcomeMessage2`, JSON.stringify(false));
		setOpen(false);
	};

	useEffect(() => {
		if (localStorage.getItem(`welcomeMessage2`)) {
			return;
		} else {
			// si user na pas encore donner de choix
			setOpen(true);
		}
	}, []);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-60 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				open={open}
				onClose={setOpen}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-primary bg-opacity-90 transition-opacity" />
					</Transition.Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-0">
							<img src={Image} alt="" className="max-h-96" />
							<div className="sm:p-6 px-4 pt-5 pb-4">
								<div>
									<div className="mt-3 text-center sm:mt-5">
										{/* <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Payment successful
                    </Dialog.Title> */}
										<H2 align="center">
											<span className="capitalize typoSecondary text-3xl">
												Salam alaykoum
											</span>
										</H2>

										<div className="mt-2">
											<p className="text-sm text-gray-500">
												Nous sommes ravis de vous annoncer le lancement de
												notre plateforme en ligne proposant des
												rediffusions, des diffusions en direct ainsi que des
												cours d'islam, animés par le Professeur et Imam
												Nader Abou Anas ! Inscrivez-vous dès maintenant pour
												en profiter !
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
									<Link
										to="https://app.dourous.net/connexion/inscription"
										onClick={() => noLongerSeeMessage()}
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-primary focus:outline-none sm:col-start-2 sm:text-sm animate"
									>
										Inscription
									</Link>
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
										onClick={() => noLongerSeeMessage()}
										ref={cancelButtonRef}
									>
										Ne plus afficher
									</button>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
