// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audios-js": () => import("./../../../src/pages/audios.js" /* webpackChunkName: "component---src-pages-audios-js" */),
  "component---src-pages-coran-js": () => import("./../../../src/pages/coran.js" /* webpackChunkName: "component---src-pages-coran-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-nader-abou-anas-js": () => import("./../../../src/pages/nader-abou-anas.js" /* webpackChunkName: "component---src-pages-nader-abou-anas-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-watch-video-js": () => import("./../../../src/pages/watch-video.js" /* webpackChunkName: "component---src-pages-watch-video-js" */)
}

