/**
 * Page Lecteur Audio
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 * https://blog.logrocket.com/4-ways-to-render-large-lists-in-react/
 */
import React, { useState } from 'react';
import AudioPlayer from 'react-h5-audio-player'; /*https://www.npmjs.com/package/react-h5-audio-player*/
import '../styles/playerAudio.css';
import ModaleSocialShare from '../components/functional/ModaleSocialShare';

export default function Audios(props) {
	const { sound } = props;

	let title, download, share;
	const [openModal, setOpenModal] = useState(false);

	if (sound) {
		title = (
			<div className="font-semibold text-white text-xs lg:text-sm pr-5 lg:pr-0">
				{sound.title}
			</div>
		);
	}

	if (sound) {
		download = (
			<a
				href={sound.url}
				target="_blank"
				rel="noreferrer"
				download={`${sound.title}.mp3`}
				className="text-xs bg-primary bg-opacity-30 hover:bg-opacity-100 text-white hover:text-white mt-2 px-3 py-2 animate rounded-sm"
			>
				<span className="flex items-center justify-start">
					<svg
						className="h-4 w-4 mr-2 md:mr-0 lg:mr-2"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M8 16a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 16m-7 3l3 3m0 0l3-3m-3 3V10"
						/>
					</svg>
					<span className="block md:hidden lg:block">Télécharger</span>
				</span>
			</a>
		);
	}

	if (sound) {
		share = (
			<button
				onClick={() => setOpenModal(true)}
				className="text-xs bg-primary bg-opacity-30 hover:bg-opacity-100 text-white hover:text-white mt-2 ml-4 px-3 py-2 animate rounded-sm"
			>
				<span className="flex items-center justify-start">
					<svg
						className="h-4 w-4 mr-2 md:mr-0 lg:mr-2"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path stroke="none" d="M0 0h24v24H0z" /> <circle cx="6" cy="12" r="3" />{' '}
						<circle cx="18" cy="6" r="3" /> <circle cx="18" cy="18" r="3" />{' '}
						<line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />{' '}
						<line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
					</svg>
					<span className="block md:hidden lg:block">Partager</span>
				</span>
			</button>
		);
	}

	const [opacity, setOpacity] = useState('opacity-0');
	const [visibilityPlayer, setVisibilityPlayer] = useState('');
	const [isVisible, setIsVisible] = useState(false);

	const openPlayer = () => {
		setOpacity('opacity-100');
		setVisibilityPlayer('visible');
		setIsVisible(true);
	};

	const hiddenPlayer = () => {
		setOpacity('opacity-0');
		setVisibilityPlayer('hidden');
		setIsVisible(false);
	};

	let buttonVisibility;
	if (isVisible) {
		buttonVisibility = (
			<button
				onClick={(e) => hiddenPlayer()}
				className="fixed bottom-24 right-0 z-50 rounded-full md:rounded-none bg-primary md:bg-secondary hover:bg-primary p-2 text-white"
			>
				<svg
					className="h-4 w-4"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<polyline points="6 9 12 15 18 9" />
				</svg>
			</button>
		);
	} else {
		buttonVisibility = (
			<button
				onClick={(e) => openPlayer()}
				className="fixed bottom-0 right-0 z-50 bg-primary md:bg-secondary hover:bg-primary p-2 text-white"
			>
				<svg
					className="h-4 w-4"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M5 15l7-7 7 7"
					/>
				</svg>
			</button>
		);
	}

	return (
		<>
			{openModal ? (
				<ModaleSocialShare
					setOpenModal={setOpenModal}
					urlShare={sound ? sound.url : null}
				/>
			) : null}

			{buttonVisibility}

			<div
				className={`fixed ${visibilityPlayer} bottom-0 left-0 w-full z-40 ${opacity} animate`}
			>
				<div className="flex flex-col md:flex-row items-start justify-start md:h-24">
					<div className="bg-secondary h-full w-full md:w-1/3 flex items-center justify-start pl-5 pr-5 md:pl-5 md:pr-0">
						{/* {sound  ?
                                <div className="mr-5">
                                    <img alt="Audio Nader Abou Anas" src={ImgAudio} className="mx-auto object-cover rounded-full h-16 w-16 "/>
                                </div>
                            :
                                <></>
                            } */}
						<div className="flex flex-col items-start justify-center py-5 md:py-0">
							{/* {date} */}
							{title}
							<div className="flex items-center">
								{download} {share}
							</div>
						</div>
					</div>

					<div
						className="h-full w-full md:w-2/3 flex items-center justify-center"
						style={{ background: '#202020' }}
					>
						<AudioPlayer
							autoPlay
							src={sound ? sound.url : 'aucun audio'}
							onPlay={(e) => openPlayer()}
							// other props here
							loop={true}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
