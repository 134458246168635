import { AUDIO } from './type';

const initialStateAudio = null;
let newArray;

const audioReducer = (state = initialStateAudio, action) => {
	switch (action.type) {
		case AUDIO:
			newArray = action.object;
			state = newArray;

			return { state };

		default:
			return state;
	}
};

export default audioReducer;
