import { createStore, combineReducers } from 'redux';
import Audio from './audio/reduceurAudio';
import Video from './video/reduceurVideo';
import PagingVideo from './pagingVideo/reduceurPagingVideo';
import CategoryVideo from './categoryVideo/reduceurCategoryVideo';
import PlayVideo from './playVideo/reduceurAudio';

const rootReducer = combineReducers({
	audio: Audio,
	video: Video,
	pagingVideo: PagingVideo,
	categoryVideo: CategoryVideo,
	playVideo: PlayVideo
});

// preloadedState will be passed in by the plugin
const store = (preloadedState) => {
	return createStore(rootReducer, preloadedState);
};

export default store;
