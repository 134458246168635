import { VIDEO } from './type';

const initialStateVideo = null;
let newArray;

const videoReducer = (state = initialStateVideo, action) => {
	switch (action.type) {
		case VIDEO:
			newArray = action.object;
			state = newArray;

			return { state };

		default:
			return state;
	}
};

export default videoReducer;
