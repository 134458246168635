/**
 *  * Rôle :
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';

const H2 = (props) => {
	let { children, colorTitle, align, center, className } = props;

	if (colorTitle === 'white') {
		colorTitle = 'text-white';
	} else if (!colorTitle) {
		colorTitle = 'text-primary';
	}

	if (align === 'left') {
		align = 'items-start';
		center = 'text-left';
	} else if (align === 'right') {
		align = 'items-end';
		center = 'text-right';
	} else {
		align = 'items-center';
		center = 'text-center';
	}

	return (
		<div className={`mb-5 flex flex-col items-center md:${align} ${center} ${className}`}>
			<h2 className={`${colorTitle} mb-3 font-extrabold uppercase`}> {children} </h2>
			<span className="block w-16 border-b-2 border-secondary"></span>
		</div>
	);
};

export default H2;
