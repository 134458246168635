import { CATEGORY } from './type';

const initialState = null;
let newArray;

const categoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case CATEGORY:
			newArray = action.object;
			state = newArray;

			return { state };

		default:
			return state;
	}
};

export default categoryReducer;
