/**
 * Barre de partage social
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	EmailShareButton,
	FacebookShareButton,
	PinterestShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from 'react-share';

export default function ShareInLine(props) {
	let { urlMedia, minimalStyle, url, shareHidden } = props;

	if (!minimalStyle) {
		minimalStyle = false;
	}

	// const shareUrl = document.location.href;
	let shareUrl;
	if (url) {
		shareUrl = url;
	} else if (typeof document !== `undefined`) {
		shareUrl = document.location.href;
	}

	return (
		<div
			className={`${
				minimalStyle ? 'mx-0' : 'mx-0 md:mx-10'
			} mt-5 mb-5 md:mb-20 flex items-center justify-center xl:justify-between`}
		>
			{shareHidden ? null : (
				<div className="hidden xl:flex items-center justify-center text-primary mt-5">
					<FontAwesomeIcon icon={['fa', 'share-alt']} className="mr-1.5" /> Partager{' '}
				</div>
			)}

			<div>
				{/* Facebook */}
				<FacebookShareButton url={shareUrl}>
					<div className="flex items-center justify-center text-white h-10 hover-ride-top-large animate mr-2 uppercase mt-5">
						<div
							className="text-xl w-10 h-full flex items-center justify-center"
							style={{ background: '#1f69f6' }}
						>
							<FontAwesomeIcon icon={['fab', 'facebook-f']} />
						</div>
						{!minimalStyle ? (
							<span
								className="hidden lg:flex font-light px-2 h-full items-center justify-center text-xxs bg-opacity-50"
								style={{
									background: 'rgba(31, 105, 246, 0.85) none repeat scroll 0% 0%'
								}}
							>
								Facebook
							</span>
						) : (
							<> </>
						)}
					</div>
				</FacebookShareButton>

				{/* Twitter */}
				<TwitterShareButton url={shareUrl}>
					<div className="flex items-center justify-center text-white h-10 hover-ride-top-large animate mr-2 uppercase mt-5">
						<div
							className="text-xl w-10 h-full flex items-center justify-center"
							style={{ background: '#2ca5da' }}
						>
							<FontAwesomeIcon icon={['fab', 'twitter']} />
						</div>
						{!minimalStyle ? (
							<span
								className="hidden lg:flex font-light px-2 h-full items-center justify-center text-xxs bg-opacity-50"
								style={{
									background: 'rgba(44, 165, 218, 0.85) none repeat scroll 0% 0%'
								}}
							>
								Twitter
							</span>
						) : (
							<> </>
						)}
					</div>
				</TwitterShareButton>

				{/* Watsapp */}
				<WhatsappShareButton url={shareUrl}>
					<div className="flex items-center justify-center text-white h-10 hover-ride-top-large animate mr-2 uppercase mt-5">
						<div
							className="text-xl w-10 h-full flex items-center justify-center"
							style={{ background: '#00b400' }}
						>
							<FontAwesomeIcon icon={['fab', 'whatsapp']} />
						</div>
						{!minimalStyle ? (
							<span
								className="hidden lg:flex font-light px-2 h-full items-center justify-center text-xxs bg-opacity-50"
								style={{ background: '#1ad03f' }}
							>
								Watsapp
							</span>
						) : (
							<> </>
						)}
					</div>
				</WhatsappShareButton>

				{/* Pinterest */}
				<PinterestShareButton media={urlMedia} url={shareUrl}>
					<div className="flex items-center justify-center text-white h-10 hover-ride-top-large animate mr-2 uppercase mt-5">
						<div
							className="text-xl w-10 h-full flex items-center justify-center"
							style={{ background: '#c51f26' }}
						>
							<FontAwesomeIcon icon={['fab', 'pinterest-p']} />
						</div>
						{!minimalStyle ? (
							<span
								className="hidden lg:flex font-light px-2 h-full items-center justify-center text-xxs bg-opacity-50"
								style={{ background: '#e71a21' }}
							>
								Pinterest
							</span>
						) : (
							<> </>
						)}
					</div>
				</PinterestShareButton>

				{/* Email */}
				<EmailShareButton url={shareUrl}>
					<div className="flex items-center justify-center text-white h-10 hover-ride-top-large animate uppercase mt-5">
						<div className="text-xl w-10 h-full flex items-center justify-center bg-gray-500">
							<FontAwesomeIcon icon={['fa', 'envelope-open-text']} />
						</div>
						{!minimalStyle ? (
							<span className="hidden lg:flex font-light px-2 h-full items-center justify-center text-xxs bg-gray-500 bg-opacity-80">
								Email
							</span>
						) : (
							<> </>
						)}
					</div>
				</EmailShareButton>
			</div>
		</div>
	);
}
